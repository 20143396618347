@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '../node_modules/material-design-icons/iconfont/material-icons.css';
@import './assets/commons/loader.scss';
* {
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
}

body {
	background-color: #e5e5e5 !important;
	box-sizing: border-box;
	margin-top: 40px;
	margin-bottom: 40px;
}

p {
	font-size: 14px;
}

.form-control {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	color: #939698;
	font-size: 14px;
	padding-left: 0px;
}

.form-control:focus {
	box-shadow: 0 0 0 0;
	outline: 0;
}

.form-control:disabled {
	background-color: transparent;
}

.form-select {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	color: #939698;
	font-size: 14px;
	padding-left: 0px;
}

.form-select:focus {
	box-shadow: 0 0 0 0;
	outline: 0;
}

.btn-link {
	text-decoration: none;
	color: #231f20;
}

.form-check-input:checked {
	background-color: #000;
	border-color: #000;
}

.png-icons {
	&__mb1 {
		margin-bottom: 1em;
	}
	&__20px {
		height: 20px;
	}
	&__24px {
		height: 24px;
	}
	&__48px {
		height: 48px;
	}
}

.status__waiting {
	&__icon {
		font-size: 48px !important;
		color: #6cd691;
		margin-bottom: 8px;
	}
	&__title {
		margin: 8px 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #2e2e34;
	}
	&__detail {
		margin: 16px 0;
		font-weight: 400;
		font-size: 16px;
		color: #707070;
		&__email {
			font-weight: 500;
		}
	}
	&__images {
		margin: 16px;
		img {
			margin: 0px 4px;
		}
	}
}

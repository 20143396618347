.mobile--show {
	display: none !important;
}
.desktop--show {
	display: grid !important;
}

.mobile__button--show {
	display: none !important;
}

@media (max-width: 992px) {
	.mobile--show {
		display: grid !important;
	}
	.desktop--show {
		display: none !important;
	}

	.mobile__button--show {
		display: flex !important;
	}

	.payment {
		&--hidden {
			display: none;
		}
		&--show {
			display: block;
		}
	}

	.next__button {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
}

.credit__card__input {
	background-repeat: no-repeat !important;
	background-position: right center !important;
	background-size: 34px 24px !important;
}

.btn-outline-success.active {
	background-color: #00d415;
	border-color: #00d415;
}

.btn-outline-secondary:hover {
	background-color: #f5f5f5;
	border-color: #1a1a1a;
	color: #1a1a1a;
}

.btn-outline-secondary {
	border-color: #1a1a1a;
	color: #1a1a1a;
}

.pay__cel__button {
	border-color: #8d8d8d;
	color: #8d8d8d;
}
.pay__cel__button:hover {
	border-color: #8d8d8d;
	color: #8d8d8d;
}

.alert-warning {
	border-color: #fdac41;
}

.pix__bold__text {
	color: #707070;
	font-weight: 700;
}

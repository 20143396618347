@import '../../scss/theme.scss';
@keyframes ldio-wbg2kxit8hs {
	0% {
		top: 96px;
		left: 96px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 18px;
		left: 18px;
		width: 156px;
		height: 156px;
		opacity: 0;
	}
}

.ldio-wbg2kxit8hs div {
	position: absolute;
	border-width: 4px;
	border-style: solid;
	opacity: 1;
	border-radius: 50%;
	animation: ldio-wbg2kxit8hs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-wbg2kxit8hs div:nth-child(1) {
	border-color: map-get($theme-colors, 'secondary');
}

.ldio-wbg2kxit8hs div:nth-child(2) {
	border-color: map-get($theme-colors, 'primary');
	animation-delay: -0.5s;
}

.loadingio-spinner-ripple-5l89yugl07g {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
	margin: auto;
}
.ldio-wbg2kxit8hs {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-wbg2kxit8hs div {
	box-sizing: content-box;
}
/* generated by https://loading.io/ */
.loading-inicial {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	min-height: 100vh;
	z-index: 20000;
	> .card {
		height: 100vh;
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 10px;
	height: 10px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 10px;
	height: 10px;
	margin: 1px;
	border: 1px solid map-get($theme-colors, 'dark');
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: map-get($theme-colors, 'dark') transparent transparent
		transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.neo-loading {
	right: 10px;
	width: 100%;
	height: 100%;
	margin: auto;
	align-items: center;
	align-content: center;
	display: flex;
}

.purchase__summary {
	&__title {
		padding: 16px 0px;
		font-weight: 700;
		border-bottom: 1px solid #e9e9e9;
		color: #5a5e61;
		margin-bottom: 0;
		text-transform: uppercase;
		p {
			margin-bottom: 0;
		}
	}
}

.list-group-item {
	&__title {
		font-size: 12px;
		font-weight: bold;
		color: #5a5e61;
		padding-left: 0;
		padding-right: 0;
	}
	&__products {
		border-bottom: 1px solid black;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.product {
	padding: 16px 0;
	&__title {
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: #5a5e61;
		margin-bottom: 8px;
	}
	&__description {
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 8px;
	}
	&__image {
		border-radius: 4px;
		height: 44px;
	}
}

@media (max-width: 992px) {
	.purchase__summary {
		&__title {
			padding: 16px;
			font-weight: 700;
			border-bottom: 1px solid #e9e9e9;
			color: #5a5e61;
			margin-bottom: 0;
			text-transform: uppercase;
			p {
				margin-bottom: 0;
			}
		}
	}
	.list-group-item {
		&__title {
			font-size: 12px;
			font-weight: bold;
			color: #5a5e61;
			padding-left: 16px;
			padding-right: 16px;
		}
		&__products {
			border-bottom: 1px solid black;
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 16px;
			padding-right: 16px;
		}
	}
}

.list-group-item {
	color: #707070;
}

.total__value {
	font-size: 14px;
	color: #1a1a1a;
}

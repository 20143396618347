.address {
	padding: 0 0px;
	line-height: 12px;
	font-size: 14px;
	color: #5a5e61;
	&__store {
		font-weight: 800;
	}
	p {
		margin-bottom: 0.6em;
	}
}

@media (max-width: 992px) {
	.address {
		padding: 0 16px;
		padding-bottom: 24px;
		line-height: 12px;
		color: #5a5e61;
		&__store {
			font-weight: 800;
		}
		p {
			margin-bottom: 0.6em;
		}

		border-bottom: 2px dashed #e9e9e9;
	}
}

.payment {
	.form-check {
		.form-check-label {
			font-size: 14px;
			color: #5a5e61;
		}
	}
	&__title {
		padding: 16px 0;
		font-weight: 700;
		border-bottom: 1px solid #e9e9e9;
		border-top: 1px solid #e9e9e9;
		color: #5a5e61;
		margin-bottom: 0;
		text-transform: uppercase;
		p {
			margin-bottom: 0;
		}
	}
	&__form {
		padding: 24px 0px;
		border-bottom: none;
	}
}

.form-label {
	&__payment {
		font-size: 12px;
		color: #5a5e61;
	}
}

.pix-buttons {
	background-color: transparent;
	border: 1px solid #1a1a1a;
}

.how__pay {
	margin-bottom: 15px;
}

.payment__select {
	margin-top: 0;
}
@media (max-width: 992px) {
	.payment {
		.form-check {
			.form-check-label {
				font-size: 14px;
				color: #5a5e61;
			}
		}
		&__title {
			padding: 16px;
			font-weight: 700;
			border-bottom: 1px solid #e9e9e9;
			color: #5a5e61;
			margin-bottom: 0;
			text-transform: uppercase;
			p {
				margin-bottom: 0;
			}
		}
		&__form {
			padding: 24px 16px;
		}
	}
}

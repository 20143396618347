.values {
	padding-left: 0;
	padding-right: 0;
	font-size: 14px;

	&__total {
		&__text {
			font-weight: bold;
		}
		&__value {
			font-weight: bold;
			color: #1a1a1a;
		}
	}
	&__discount {
		&__value {
			font-weight: bold;
			color: #6cd691;
		}
	}
	&__value {
		font-weight: bold;
	}
	.list-group-item {
		color: #707070;
		padding-left: 0;
		padding-right: 0;
		padding-top: 16px;
		padding-bottom: 16px;
	}
}

@media (max-width: 992px) {
	.values {
		padding-left: 1em;
		padding-right: 1em;
		font-size: 14px;

		&__total {
			&__text {
				font-weight: bold;
			}
			&__value {
				font-weight: bold;
				color: #1a1a1a;
			}
		}
		&__discount {
			&__value {
				font-weight: bold;
				color: #6cd691;
			}
		}
		&__value {
			font-weight: bold;
		}
		.list-group-item {
			padding-top: 16px;
			padding-bottom: 16px;
			color: #707070;
		}
	}
}

.status__processing {
	&__title {
		margin: 16px 0;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		color: #2e2e34;
	}
	&__detail {
		margin: 8px 0 16px;
		font-weight: 700;
		font-size: 16px;
		line-height: 36px;
		color: #707070;
	}
}

.status__canceled {
	height: 350px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	&__icon {
		font-size: 48px;
		margin-bottom: 8px;
	}
	&__title {
		margin: 8px 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #2e2e34;
	}
	&__detail {
		margin: 16px 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #707070;
	}
}

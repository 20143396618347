.logo {
	&__internal {
		display: none;
	}
	&__external {
		display: block;
		max-width: 187px;
		max-height: 72px;
	}
}
.card-body {
	padding: 40px 32px 24px 32px;
}

.card-header {
	padding-top: 40px;
	padding-bottom: 0px;
	border: none;
	background-color: #fff;
}

.return-button {
	position: absolute;
	left: 20px;
	padding: 0;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

@media (max-width: 992px) {
	.logo {
		&__internal {
			display: block;
			max-width: 187px;
			max-height: 72px;
		}
		&__external {
			display: none;
		}
	}
	.card-body {
		padding: 40px 0 24px 0;
	}

	.page__footer {
		&__left {
			margin-bottom: 8px;
		}
	}
}

.page__footer {
	margin-top: 26px;
	&__left {
		&__icon {
			color: #6b7b91;
			font-size: 20px;
			margin-right: 8px;
		}
		&__text {
			color: #6b7b91;
			font-weight: 400;
			font-size: 14px;
		}
	}

	&__right {
		&__text {
			color: #4b5f79;
			font-weight: 400;
			font-size: 12px;
			font-style: italic;
			margin-right: 8px;
		}
		&__img {
			height: 16px;
		}
	}
}
